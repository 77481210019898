import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer, Tooltip } from 'recharts';
import { ShoppingCart, Package, Loader2, AlertTriangle, Store } from 'lucide-react';
import {
    collection,
    query,
    where,
    orderBy,
    limit,
    getDocs,
    getFirestore,
    Timestamp,
    doc,
    getDoc,
    updateDoc,
} from 'firebase/firestore';
import { useAuth } from '../../auth/AuthContext';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../components/sidebar';
import Dashboard from '../components/dashboard';

const TimePeriodSelect = ({ value, onChange }) => (
    <select
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
    >
        <option value="7days">Last 7 Days</option>
        <option value="30days">Last 30 Days</option>
        <option value="90days">Last 90 Days</option>
    </select>
);

const LoadingSpinner = () => (
    <div className="flex items-center justify-center flex-1">
        <div className="flex flex-col items-center gap-3">
            <Loader2 className="h-8 w-8 animate-spin text-gray-600" />
            <div className="text-lg text-gray-600">Loading dashboard data...</div>
        </div>
    </div>
);

const ErrorMessage = ({ message }) => (
    <div className="flex items-center justify-center flex-1 p-4 bg-red-50 text-red-500 rounded-lg">
        <AlertTriangle className="mr-2 h-5 w-5" />
        <div>{message}</div>
    </div>
);

const Lobby = () => {
    const [lobbyData, setLobbyData] = useState({
        monthlySales: 0,
        itemsSold: 0,
        lowStockItems: 0,
        yearToDateRevenue: 0,
        salesChart: [],
        isLoading: true,
        error: null
    });
    const [storeData, setStoreData] = useState(null);
    const [timePeriod, setTimePeriod] = useState('7days');
    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    const db = getFirestore();
    const auth = getAuth();
    const storeId = localStorage.getItem('storeId');
    const { user: authUser } = useAuth();

    useEffect(() => {
        const fetchStoreData = async () => {
            if (!storeId) {
                setLobbyData(prev => ({
                    ...prev,
                    error: 'Store ID not found'
                }));
                return;
            }

            try {
                const storeDoc = await getDoc(doc(db, 'Stores', storeId));
                if (storeDoc.exists()) {
                    setStoreData(storeDoc.data());
                } else {
                    throw new Error('Store not found');
                }
            } catch (error) {
                console.error('Error fetching store data:', error);
                setLobbyData(prev => ({
                    ...prev,
                    error: 'Failed to load store data'
                }));
            }
        };

        fetchStoreData();
    }, [db, storeId]);


    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const currentUser = auth.currentUser;
                if (!currentUser) {
                    throw new Error('No authenticated user found');
                }

                // First try to get user by email
                let userDoc = await getDocs(
                    query(
                        collection(db, 'Users'),
                        where('email', '==', currentUser.email)
                    )
                );

                // If not found and we have a Google user, update the user document
                if (userDoc.empty && currentUser.providerData[0]?.providerId === 'google.com') {
                    // Get the user doc by UID since we know it exists from Google login
                    const userDocRef = doc(db, 'Users', currentUser.uid);
                    const userSnapshot = await getDoc(userDocRef);

                    if (userSnapshot.exists()) {
                        // Update the user document with email
                        await updateDoc(userDocRef, {
                            email: currentUser.email
                        });

                        // Get the updated document
                        const updatedDoc = await getDoc(userDocRef);
                        setUser(updatedDoc.data());
                    } else {
                        throw new Error('User document not found');
                    }
                } else if (!userDoc.empty) {
                    // We found the user by email
                    setUser(userDoc.docs[0].data());
                } else {
                    throw new Error('User data not found');
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
                setLobbyData(prev => ({
                    ...prev,
                    isLoading: false,
                    error: 'Failed to authenticate user'
                }));
            }
        };

        if (authUser) {
            fetchUserData();
        }
    }, [db, authUser]);

    useEffect(() => {
        const fetchLobbyData = async () => {
            if (!user?.storeId) return;

            try {
                const now = new Date();
                const monthStart = new Date(now.getFullYear(), now.getMonth(), 1);
                const yearStart = new Date(now.getFullYear(), 0, 1);
                let timeFilter = new Date();

                switch (timePeriod) {
                    case '30days':
                        timeFilter.setDate(timeFilter.getDate() - 30);
                        break;
                    case '90days':
                        timeFilter.setDate(timeFilter.getDate() - 90);
                        break;
                    default: // 7days
                        timeFilter.setDate(timeFilter.getDate() - 7);
                }

                const monthlySalesQuery = query(
                    collection(db, 'Stores', user.storeId, 'Sales'),
                    where('saleDate', '>=', Timestamp.fromDate(monthStart))
                );

                const ytdSalesQuery = query(
                    collection(db, 'Stores', user.storeId, 'Sales'),
                    where('saleDate', '>=', Timestamp.fromDate(yearStart))
                );

                const recentSalesQuery = query(
                    collection(db, 'Stores', user.storeId, 'Sales'),
                    where('saleDate', '>=', Timestamp.fromDate(timeFilter)),
                    orderBy('saleDate', 'desc')
                );

                const inventoryQuery = query(
                    collection(db, 'Stores', user.storeId, 'Inventory')
                );

                const [monthlySalesSnapshot, ytdSalesSnapshot, recentSalesSnapshot, inventorySnapshot] =
                    await Promise.all([
                        getDocs(monthlySalesQuery),
                        getDocs(ytdSalesQuery),
                        getDocs(recentSalesQuery),
                        getDocs(inventoryQuery)
                    ]);

                let monthlyTotal = 0;
                let monthlyItems = 0;
                monthlySalesSnapshot.forEach(doc => {
                    const sale = doc.data();
                    monthlyTotal += sale.items.reduce((sum, item) =>
                        sum + (item.price * item.quantity), 0);
                    monthlyItems += sale.items.reduce((sum, item) =>
                        sum + item.quantity, 0);
                });

                let ytdTotal = 0;
                ytdSalesSnapshot.forEach(doc => {
                    const sale = doc.data();
                    ytdTotal += sale.items.reduce((sum, item) =>
                        sum + (item.price * item.quantity), 0);
                });

                let lowStockCount = 0;
                inventorySnapshot.forEach(doc => {
                    const item = doc.data();
                    if (item.quantity <= item.reorderPoint) {
                        lowStockCount++;
                    }
                });

                const dailyTotals = {};
                recentSalesSnapshot.forEach(doc => {
                    const sale = doc.data();
                    const dateKey = sale.saleDate.toDate().toISOString().split('T')[0];
                    const saleTotal = sale.items.reduce((sum, item) =>
                        sum + (item.price * item.quantity), 0);
                    dailyTotals[dateKey] = (dailyTotals[dateKey] || 0) + saleTotal;
                });

                const chartData = Object.entries(dailyTotals)
                    .map(([date, sales]) => ({
                        date,
                        sales
                    }))
                    .sort((a, b) => new Date(a.date) - new Date(b.date));

                setLobbyData({
                    monthlySales: monthlyTotal,
                    itemsSold: monthlyItems,
                    lowStockItems: lowStockCount,
                    yearToDateRevenue: ytdTotal,
                    salesChart: chartData,
                    isLoading: false,
                    error: null
                });

            } catch (error) {
                console.error('Error fetching lobby data:', error);
                setLobbyData(prev => ({
                    ...prev,
                    isLoading: false,
                    error: 'Failed to load dashboard data'
                }));
            }
        };

        fetchLobbyData();
    }, [user, db, timePeriod]);

    if (lobbyData.isLoading) {
        return (
            <div className="flex h-screen bg-white overflow-hidden">
                <Sidebar />
                <LoadingSpinner />
            </div>
        );
    }

    if (lobbyData.error) {
        return (
            <div className="flex h-screen bg-white overflow-hidden">
                <Sidebar />
                <ErrorMessage message={lobbyData.error} />
            </div>
        );
    }

    return (
        <div className="flex h-screen bg-white overflow-hidden">
            <Sidebar />
            <div className="flex-1 flex flex-col overflow-hidden">
                <Dashboard lobbyData={lobbyData} storeData={storeData} />
            </div>
        </div>
    );
};

export default Lobby;