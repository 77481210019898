import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';
import { auth } from '../../firebase-config';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, getFirestore } from 'firebase/firestore';

const AuthContext = createContext();
const STORAGE_KEY = 'auth_persistence';
const TEMP_ACCESS_KEY = 'temp_access_state';

export function useAuth() {
    return useContext(AuthContext);
}

export const ACCESS_LEVELS = {
    OWNER: 1,
    CASHIER: 2,
    ADMIN: 3
};

export const PLAN_LEVELS = {
    TRIAL: 0,
    BASIC: 1,
    PRO: 2,
    ENTERPRISE: 3
};

const initializeDB = async () => {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open('storeDB', 1);
        request.onerror = () => reject(request.error);
        request.onsuccess = () => resolve(request.result);
        request.onupgradeneeded = (event) => {
            const db = event.target.result;
            if (!db.objectStoreNames.contains('sales')) {
                db.createObjectStore('sales', { keyPath: 'id', autoIncrement: true });
            }
            if (!db.objectStoreNames.contains('inventory')) {
                db.createObjectStore('inventory', { keyPath: 'id', autoIncrement: true });
            }
        };
    });
};

const persistTempAccessState = (tempState) => {
    try {
        if (tempState) {
            localStorage.setItem(TEMP_ACCESS_KEY, JSON.stringify({
                temporaryAccessLevel: tempState.temporaryAccessLevel,
                originalAccessLevel: tempState.originalAccessLevel
            }));
        } else {
            localStorage.removeItem(TEMP_ACCESS_KEY);
        }
    } catch (error) {
        console.error('Error persisting temporary access state:', error);
    }
};

const getPersistedTempAccessState = () => {
    try {
        const state = localStorage.getItem(TEMP_ACCESS_KEY);
        return state ? JSON.parse(state) : null;
    } catch (error) {
        console.error('Error retrieving persisted temporary access state:', error);
        return null;
    }
};

const persistAuthState = (state) => {
    try {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(state));
    } catch (error) {
        console.error('Error persisting auth state:', error);
    }
};

const getPersistedAuthState = () => {
    try {
        const state = localStorage.getItem(STORAGE_KEY);
        return state ? JSON.parse(state) : null;
    } catch (error) {
        console.error('Error retrieving persisted auth state:', error);
        return null;
    }
};

export function AuthProvider({ children }) {
    const [user, setUser] = useState(() => getPersistedAuthState());
    const [loading, setLoading] = useState(true);
    const [lastKnownAccessLevel, setLastKnownAccessLevel] = useState(null);
    const [accessLevel, setAccessLevel] = useState(null);
    const [planLevel, setPlanLevel] = useState(null);
    const [trialStatus, setTrialStatus] = useState(null);
    const [db, setDb] = useState(null);
    const [offlineMode, setOfflineMode] = useState(false);
    const firestore = getFirestore();

    const persistedTempState = getPersistedTempAccessState();
    const [originalAccessLevel, setOriginalAccessLevel] = useState(persistedTempState?.originalAccessLevel || null);
    const [temporaryAccessLevel, setTemporaryAccessLevel] = useState(persistedTempState?.temporaryAccessLevel || null);

    const updateUserState = useCallback((newState) => {
        setUser(newState);
        persistAuthState(newState);
    }, []);

    const setTemporaryAccess = useCallback((level) => {
        if (!originalAccessLevel) {
            setOriginalAccessLevel(accessLevel);
        }
        setTemporaryAccessLevel(level);

        persistTempAccessState({
            temporaryAccessLevel: level,
            originalAccessLevel: originalAccessLevel || accessLevel
        });
    }, [accessLevel, originalAccessLevel]);

    const clearTemporaryAccess = useCallback(() => {
        setTemporaryAccessLevel(null);
        setOriginalAccessLevel(null);
        persistTempAccessState(null);
    }, []);

    const refreshPlanLevel = useCallback(async () => {
        if (user?.storeId) {
            try {
                const storeDoc = await getDoc(doc(firestore, 'Stores', user.storeId));
                if (storeDoc.exists()) {
                    const storeData = storeDoc.data();
                    const currentPlanLevel = storeData.planAuthorizationLevel || PLAN_LEVELS.TRIAL;
                    const currentTrialStatus = storeData.trialStatus;

                    setPlanLevel(currentPlanLevel);
                    setTrialStatus(currentTrialStatus);
                    updateUserState(prevUser => ({
                        ...prevUser,
                        planLevel: currentPlanLevel,
                        trialStatus: currentTrialStatus
                    }));
                }
            } catch (error) {
                console.error('Error refreshing plan level:', error);
            }
        }
    }, [user?.storeId, firestore, updateUserState]);

    const fetchUserData = useCallback(async (uid, retryCount = 3) => {
        for (let i = 0; i < retryCount; i++) {
            try {
                const userDoc = await getDoc(doc(firestore, 'Users', uid));
                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    setLastKnownAccessLevel(userData.accessLevel);

                    const storeDoc = await getDoc(doc(firestore, 'Stores', userData.storeId));
                    let currentPlanLevel = PLAN_LEVELS.TRIAL;
                    let currentTrialStatus = null;

                    if (storeDoc.exists()) {
                        const storeData = storeDoc.data();
                        currentPlanLevel = storeData.planAuthorizationLevel || PLAN_LEVELS.TRIAL;
                        currentTrialStatus = storeData.trialStatus;
                    }

                    return {
                        ...userData,
                        planLevel: currentPlanLevel,
                        trialStatus: currentTrialStatus
                    };
                }
            } catch (error) {
                console.error(`Attempt ${i + 1} failed:`, error);
                if (i === retryCount - 1) throw error;
                await new Promise(resolve => setTimeout(resolve, 1000 * (i + 1)));
            }
        }
    }, [firestore]);

    useEffect(() => {
        if (user && !db) {
            initializeDB()
                .then(database => setDb(database))
                .catch(error => console.error('Error initializing IndexedDB:', error));
        }
    }, [user, db]);

    useEffect(() => {
        if (!user && db) {
            db.close();
            setDb(null);
        }
    }, [user, db]);

    useEffect(() => {
        const handleOffline = () => setOfflineMode(true);
        const handleOnline = () => setOfflineMode(false);

        window.addEventListener('offline', handleOffline);
        window.addEventListener('online', handleOnline);

        let authInitialized = false;
        let isActive = true;

        const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
            if (!authInitialized) {
                authInitialized = true;
                console.log('Firebase Auth initialized');
            }

            if (firebaseUser && isActive) {
                try {
                    const userData = await fetchUserData(firebaseUser.uid);
                    if (!isActive) return;

                    if (userData) {
                        setAccessLevel(userData.accessLevel || ACCESS_LEVELS.CASHIER);
                        setPlanLevel(userData.planLevel);
                        setTrialStatus(userData.trialStatus);
                        updateUserState({
                            ...firebaseUser,
                            accessLevel: userData.accessLevel,
                            storeId: userData.storeId,
                            planLevel: userData.planLevel,
                            trialStatus: userData.trialStatus,
                            authInitialized: true
                        });
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                    const persistedState = getPersistedAuthState();
                    if (persistedState && persistedState.accessLevel) {
                        setAccessLevel(persistedState.accessLevel);
                        setPlanLevel(persistedState.planLevel);
                        setTrialStatus(persistedState.trialStatus);
                        updateUserState({
                            ...firebaseUser,
                            ...persistedState,
                            authInitialized: true
                        });
                    } else if (lastKnownAccessLevel) {
                        setAccessLevel(lastKnownAccessLevel);
                        setPlanLevel(PLAN_LEVELS.TRIAL);
                        updateUserState({
                            ...firebaseUser,
                            accessLevel: lastKnownAccessLevel,
                            planLevel: PLAN_LEVELS.TRIAL,
                            authInitialized: true
                        });
                    } else {
                        setAccessLevel(ACCESS_LEVELS.CASHIER);
                        setPlanLevel(PLAN_LEVELS.TRIAL);
                        updateUserState({
                            ...firebaseUser,
                            accessLevel: ACCESS_LEVELS.CASHIER,
                            planLevel: PLAN_LEVELS.TRIAL,
                            authInitialized: true
                        });
                    }
                }
            } else {
                setAccessLevel(null);
                setPlanLevel(null);
                setTrialStatus(null);

                setAccessLevel(null);
                setPlanLevel(null);
                setTrialStatus(null);
                setTemporaryAccessLevel(null);
                setOriginalAccessLevel(null);
                // Clear persisted states
                localStorage.removeItem(TEMP_ACCESS_KEY);
                localStorage.removeItem(STORAGE_KEY);
                localStorage.removeItem('store_lockdown_status');
                updateUserState(null);
            }

            if (authInitialized) {
                setLoading(false);
            }

        });

        return () => {
            isActive = false;
            window.removeEventListener('offline', handleOffline);
            window.removeEventListener('online', handleOnline);
            unsubscribe();
        };
    }, [auth, fetchUserData, updateUserState, lastKnownAccessLevel]);

    // In your AuthProvider
    useEffect(() => {
        if (user) {
            const interval = setInterval(async () => {
                try {
                    await auth.currentUser?.getIdToken(true);
                } catch (error) {
                    console.error('Token refresh failed:', error);
                }
            }, 10 * 60 * 1000); // Every 10 minutes

            return () => clearInterval(interval);
        }
    }, [user]);

    const value = {
        user,
        loading,
        offlineMode,
        accessLevel,
        planLevel,
        trialStatus,
        refreshPlanLevel,
        temporaryAccessLevel,
        setTemporaryAccess,
        clearTemporaryAccess,
        hasOwnerPrivileges: originalAccessLevel ? originalAccessLevel === ACCESS_LEVELS.OWNER : accessLevel === ACCESS_LEVELS.OWNER,
        isOwner: temporaryAccessLevel ? temporaryAccessLevel === ACCESS_LEVELS.OWNER : accessLevel === ACCESS_LEVELS.OWNER,
        isAdmin: temporaryAccessLevel ? temporaryAccessLevel === ACCESS_LEVELS.ADMIN : accessLevel === ACCESS_LEVELS.ADMIN,
        hasFullAccess: temporaryAccessLevel ?
            (temporaryAccessLevel === ACCESS_LEVELS.OWNER || temporaryAccessLevel === ACCESS_LEVELS.ADMIN) :
            (accessLevel === ACCESS_LEVELS.OWNER || accessLevel === ACCESS_LEVELS.ADMIN),
        isTrial: planLevel === PLAN_LEVELS.TRIAL,
        isBasic: planLevel === PLAN_LEVELS.BASIC,
        isPro: planLevel === PLAN_LEVELS.PRO,
        isEnterprise: trialStatus === "active" ? true : planLevel === PLAN_LEVELS.ENTERPRISE,
        hasBasicAccess: trialStatus === "active" ? true : planLevel >= PLAN_LEVELS.BASIC,
        hasProAccess: trialStatus === "active" ? true : planLevel >= PLAN_LEVELS.PRO,
        hasEnterpriseAccess: trialStatus === "active" ? true : planLevel === PLAN_LEVELS.ENTERPRISE,
        indexedDB: db
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
}