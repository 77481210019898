import React from 'react';
import { Star } from 'lucide-react';

const Reviews = () => {
    const reviews = [
        {
            author: "Adam Kumah Kaeti",
            content: "Exactly as described and expected, especially the detailed reports. It has a sleek and intuitive user interface. Highly recommended 👌",
            rating: 5,
            date: "16/11/2024"
        },
        {
            author: "Bbrightson",
            content: "This POS app is truly a game-changer and for a typical Ghanaian use case! It’s incredibly straightforward and gets the job done without any hassle setting it up. The user interface is simple and clean, making it super easy to navigate, while the overall user experience feels smooth and well thought out. It’s the perfect solution for anyone looking for an effective and user-friendly POS system. TBH you will just love this. Highly recommend! Go for it 🤝",
            rating: 5,
            date: "20/01/2025"
        },
    ];

    const getRelativeTime = (dateString) => {
        const date = new Date(dateString);
        const now = new Date();
        const diffInMs = now - date;
        const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
        const diffInMonths = Math.floor(diffInDays / 30);
        const diffInYears = Math.floor(diffInDays / 365);

        if (diffInDays < 1) {
            return 'today';
        } else if (diffInDays === 1) {
            return 'yesterday';
        } else if (diffInDays < 7) {
            return `${diffInDays} days ago`;
        } else if (diffInDays < 14) {
            return '1 week ago';
        } else if (diffInDays < 30) {
            return `${Math.floor(diffInDays / 7)} weeks ago`;
        } else if (diffInMonths === 1) {
            return '1 month ago';
        } else if (diffInMonths < 12) {
            return `${diffInMonths} months ago`;
        } else if (diffInYears === 1) {
            return '1 year ago';
        } else {
            return `${diffInYears} years ago`;
        }
    };

    return (
        <section className="py-20 bg-gray-800">
            <div className="container mx-auto px-4">
                <div className="text-center mb-16">
                    <h2 className="text-4xl font-bold mb-4">What Our Users Say</h2>
                    <p className="text-gray-400 text-xl">
                        Join satisfied business owners using HotKiosk
                    </p>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-6xl mx-auto">
                    {reviews.map((review, index) => (
                        <div
                            key={index}
                            className="bg-gray-900 rounded-xl p-6 hover:transform hover:scale-105 transition-all duration-300"
                        >
                            <div className="flex justify-between items-center mb-3">
                                <h3 className="font-semibold text-sm text-gray-300">{review.author}</h3>
                                {/* <span className="text-xs text-gray-500">{getRelativeTime(review.date)}</span> */}
                            </div>

                            <div className="flex mb-3">
                                {[...Array(review.rating)].map((_, i) => (
                                    <Star
                                        key={i}
                                        className="w-4 h-4 text-[#FF6B35] fill-current"
                                    />
                                ))}
                            </div>

                            <p className="text-gray-400 text-sm leading-relaxed">{review.content}</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Reviews;